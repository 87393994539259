<template>
  <div class="roleManage">
    <div class="search_box">
      <el-form
        :inline="true"
        ref="roleSearchForm"
        :model="roleSearchForm"
        label-width="100px"
      >
        <el-form-item label="角色：">
          <el-select v-model="roleSearchForm.staffType">
            <el-option label="角色1" value="1"></el-option>
          </el-select>
        </el-form-item>
        <div class="stafflist_ssgn" @click="getRoleList">
          <el-button>搜索</el-button>
          <el-button type="primary">添加</el-button>
        </div>
      </el-form>
    </div>
    <el-table :data="roleList" border class="table" v-loading="tableLoading">
      <el-table-column
        label="角色編號"
        prop="id"
        align="center"
      ></el-table-column>

      <!-- <el-table-column label="商戶號" prop="shh"></el-table-column> -->
      <el-table-column
        label="角色名稱"
        prop="name"
        align="center"
        :show-overflow-tooltip="true"
      >
      </el-table-column>
      <el-table-column
        label="顯示順序"
        prop="people_name"
        align="center"
      ></el-table-column>
      <el-table-column
        label="狀態"
        prop="address"
        align="center"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        label="創建時間"
        prop="address"
        align="center"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column label="操作" width="190">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="info"
            icon="el-icon-edit"
            @click="edit(scope.row.id)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="info"
            icon="el-icon-edit"
            @click="delRole(scope.row.id)"
            >刪除</el-button
          >
          <el-button
            size="mini"
            type="info"
            icon="el-icon-edit"
            @click="delRole(scope.row.id)"
            >權限設置</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分頁 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageIndex"
      :page-sizes="[5, 10, 20]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
export default {
  name: "roleManage",
  data() {
    return {
      roleSearchForm: {
        staffType: "",
      },
      tableLoading: false,
      roleList: [],
    };
  },
  methods: {
    // 獲取角色列表
    getRoleList() {},
    // 編輯
    edit() {},
    // 刪除
    delRole() {},
  },
};
</script>
<style lang="scss" scoped>
.roleManage {
  width: 100%;
  padding: 10px;
  .search_box {
    padding: 10px;
    .stafflist_ssgn {
      display: inline-block;
    }
  }
}
</style>